<template>
  <div>
    <template v-if="PERMISIONS.list">
      <table-parent
        style="padding: 0; box-shadow: none; border-radius: 0px;"
        :reloadCount="reloadTable"
        :tableHead="tableHead"
        :prepareFn="prepareData"
        :apiModule="tableModule"
        apiModuleName="ACQUISITION_PRODUCTS"
        :tableMinimizeHead="true"
        :tableSpread="true"
        @Btn2="editProduct"
        @totalRecords="(x) => (totalRecords = x)"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "../mixins/acquisitionProductsPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { ACQUISITION_PRODUCTS } from "../api.js";
import DialogModal from "@/components/DialogModal";
import TableParent from "@/components/TableParent";
import EditAcquisitionProduct from "@/components/EditAcquisitionProduct";

export default {
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  props: {
    acquisition: { type: Object, required: true },
  },
  data() {
    return {
      tableHead: [
        {
          title: "Produs",
          queryKey: "need.product.name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoare unitară ințială",
          queryKey: "need.estimatedPriceWoTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoare unitară",
          queryKey: "unitValue",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cotă TVA ințială",
          queryKey: "need.cotaTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cotă TVA",
          queryKey: "tvaValue",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cantitate inițială",
          queryKey: "need.qty",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Cantitate",
          queryKey: "quantity",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea totală, fără TVA",
          queryKey: "totalValueWithoutTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Valoarea totală, cu TVA",
          queryKey: "totalValueWithTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          queryKey: "status",
          bottomHead: {
            quikFiltre: "acquisitionProductStatus",
          },
        },
      ],
      tableModule: (x, y) =>
        ACQUISITION_PRODUCTS.get(
          x,
          y,
          `eq=acquisitionId,${this.acquisition.id}`
        ),
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },
    prepareData(row) {
      const preparation = [
        row.need.product.name || "-",
        this.prepareLocaleNumber(row.need.estimatedPriceWoTva) || "-",
        this.prepareLocaleNumber(row.unitValue) || "-",
        this.prepareLocaleNumber(row.need.cotaTva) || "-",
        this.prepareLocaleNumber(row.tvaValue) || "-",
        this.prepareLocaleNumber(row.need.qty) || "-",
        this.prepareLocaleNumber(row.quantity) || "-",
        this.prepareLocaleNumber(row.totalValueWithoutTva) || "-",
        this.prepareLocaleNumber(row.totalValueWithTva) || "-",
        {
          draft: {
            border: "left",
            icon: "far fa-clock",
            css: "padding: 0.5rem;color: #f2da07;font-size: 2rem;",
            tooltip: "Draft",
          },
          completed: {
            border: "left",
            icon: "fas fa-clipboard-check",
            css: "padding: 0.5rem;color: #569a36;font-size: 2rem;",
            tooltip: "Completat",
          },
        }[row.status] || "-",
      ];

      const x = this.PERMISIONS;
      const prepareBtn = [];

      if (x.edit === true) {
        prepareBtn.push(2);
      }

      preparation.push([prepareBtn]);

      return preparation;
    },

    editProduct(item) {
      if (
        this.acquisition.status == "approved" ||
        this.acquisition.status == "waiting_approve" ||
        this.acquisition.status == "closed"
      ) {
        this.$toastr.w("Produsul nu mai poate fi modificat.");
        return;
      }
      this.$modal.show(
        DialogModal,
        {
          target: "AcquisitionProductsEdit",
          title: "Completare produs",
          inputs: [
            {
              id: "acquisitionProduct",
              type: "component",
              component: EditAcquisitionProduct,
              componentProps: { acquisitionProduct: item },
            },
          ],
          closeBtnText: "Anulează",
          acceptFinish(data) {
            const acquisitionProduct = data.acquisitionProduct;
            for (const key in acquisitionProduct) {
              if (!acquisitionProduct[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }
            return true;
          },
          button: {
            type: 2,
            value: "Completează",
            handler: (sendData) => {
              const acquisitionProduct = sendData.acquisitionProduct;
              const error = (msg) => {
                this.$toastr.e(msg || "Completarea produsului a eșuat.");
              };

              this.setSafeLoad(12000);

              ACQUISITION_PRODUCTS.edit(acquisitionProduct.id, {
                ...acquisitionProduct,
                status: "completed",
              })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Completarea produsului a fost efectuat.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "AcquisitionProductsEdit",
          adaptive: true,
          width: "700px",
          height: "610px",
        }
      );
    },
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    const canViewButtonsColumn = ["edit", "delete"];

    this.updateRoleStatus();
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }
    this.$emit("input", this.acquisition);
  },
};
</script>
<style></style>
